  .image-item {
    display: flex;
    margin: 10px 0;
    position: relative;

  }
  .image-item__btn-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  button {
    height: 40px;
  }

  .button-delete {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #f0f0f0;
    border: none;
    padding: 5px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;         
    align-items: center;     
    justify-content: center; 
  }

  .button-delete:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  
  